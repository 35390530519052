import React from "react";
import connect from "../assets/connect.png";
import { motion } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/connectStyles.css";

const Connect = ({ scrollToPricing, scrollToFreeDemo }) => {
  const leftToRightVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const rightToLeftVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  return (
    <>
      <div className="container connect-container mt-5">
        <div className="row">
          <motion.div
            className="col-md-6 connect-left"
            initial="hidden"
            whileInView="visible"
            variants={leftToRightVariant}
            viewport={{ once: true }}
          >
            <p
              className="mt-5"
              style={{
                fontFamily: "Lato, sans-serif",
                color: "#26017b",
                fontSize: "40px",
                fontWeight: "bold",
              }}
            >
              Here for{" "}
              <span style={{ color: "#00a5e0" }}>You Around the Clock</span>
            </p>
            <ul
              className="list-unstyled connect-list mt-5 fw-bold fs-5 connect-list"
              style={{ color: "#26017b" }}
            >
              <li>
                Get expert assistance anytime via live chat and email support
                for prompt resolutions.
              </li>
              <li>
                Our team speaks 10+ languages, ensuring seamless communication.
              </li>
              <li>
                Experience swift support with typical response times under 2
                minutes.
              </li>
            </ul>
            <a
              href="/contact"
              className="btn btn-outline-primary fs-4 connect-button"
            >
              Connect Us 24/7
            </a>
          </motion.div>
          <motion.div
            className="col-md-6 connect-right d-flex justify-content-center align-items-center"
            initial="hidden"
            whileInView="visible"
            variants={rightToLeftVariant}
            viewport={{ once: true }}
          >
            <img
              src={connect}
              alt=" Mobile app development, Search engine optimization, Digital marketing strategies, Online advertising, Marketing automation, Creative branding agency, Growth marketing, Local SEO services, Web development company, Graphic design services"
              className="img-fluid"
            />
          </motion.div>
        </div>
      </div>

      {/*{showPopup && (
        <div className="popup-overlay" onClick={toggleQuotePopup}>
          <div
            className="popup-content quote-popup"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="popup-close" onClick={toggleQuotePopup}>
              &times;
            </button>
            <h2
              className="text-center fw-bold fs-1 mb-1"
              style={{
                color: "#00a5e0",
              }}
            >
              CONNECT US
            </h2>
            <p
              className="text-center fw-bold"
              style={{
                color: "#26017b",
              }}
            >
              WE ARE READY TO HELP YOU 24/7
            </p>

            <div className="quote-popup-container d-flex flex-column flex-md-row">
              <div className="quote-left flex-fill d-flex flex-column align-items-center mb-3 mb-md-0">
                <button
                  className="quote-btn btn w-100 mb-2" // Use mb-2 for spacing
                  onClick={scrollToFreeDemo}
                  style={{
                    border: "3px solid #26017b",
                    transition: "transform 0.2s",
                    color: "#26017b",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.03)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                >
                  Arrange a <span style={{ color: "#00a5e0" }}>Meeting</span>
                  <p
                    style={{
                      marginTop: "0rem",
                      fontSize: "12px",
                      textWrap: "nowrap",
                      color: "#00a5e0",
                      alignItems: "left",
                    }}
                  >
                    A meet call for your business discussion
                  </p>
                </button>

                <button
                  className="quote-btn btn w-100 mb-2" // Use mb-2 for spacing
                  onClick={handleContactUsClick}
                  style={{
                    border: "3px solid #26017b",
                    transition: "transform 0.2s",
                    color: "#26017b",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.03)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                >
                  Contact Us{" "}
                  <p
                    style={{
                      marginTop: "0rem",
                      fontSize: "12px",
                      textWrap: "nowrap",
                      color: "#00a5e0",
                    }}
                  >
                    Reserve your spot
                  </p>
                </button>

                <button
                  className="quote-btn btn w-100 mb-2" // Use mb-2 for spacing
                  onClick={handleWhatsAppRedirect}
                  style={{
                    border: "3px solid #26017b",
                    transition: "transform 0.2s",
                    color: "#26017b",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.03)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                >
                  WhatsApp{" "}
                  <p
                    style={{
                      marginTop: "0rem",
                      fontSize: "12px",
                      textWrap: "nowrap",
                      color: "#00a5e0",
                    }}
                  >
                    Chat for smooth conversion
                  </p>
                </button>

                <button
                  className="quote-btn btn w-100" // No margin bottom for the last button
                  onClick={handleContactUsClick}
                  style={{
                    border: "3px solid #26017b",
                    transition: "transform 0.2s",
                    color: "#26017b",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.03)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                >
                  Social Link{" "}
                  <span className="quote-btn-icon" data-link="twitter"></span>
                  <p
                    style={{
                      marginTop: "0rem",
                      fontSize: "12px",
                      textWrap: "nowrap",
                      color: "#00a5e0",
                    }}
                  >
                    Let’s connect through our social accounts
                  </p>
                </button>
              </div>

              <div className="quote-right flex-fill d-none d-md-flex justify-content-center align-items-center">
                <img
                  src={Popupimg}
                  alt="Self-confidence"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      )}*/}
    </>
  );
};

export default Connect;
