import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import heroImage from "../assets/hero.png";
import ai from "../assets/icons/ai.png";
import timer from "../assets/icons/timer.png";
import { motion } from "framer-motion";
import "../styles/hero.css";

const Hero = ({ scrollToPricing }) => {
  const leftRightVariants = {
    hidden: { x: "-100vw", opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, damping: 20, duration: 1.5 },
    },
  };

  const [currentTime, setCurrentTime] = useState(new Date());
  const [isLimitedTime, setIsLimitedTime] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    setIsLimitedTime(true);

    return () => clearInterval(timer);
  }, [currentTime]);

  const getTimeLeft = () => {
    const startOfMonth = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      1
    );
    const daysPassed =
      Math.floor((currentTime - startOfMonth) / (1000 * 60 * 60 * 24)) + 1;
    const daysInPeriod = 2 - ((daysPassed - 1) % 2); // Change from 10 to 2-day period
    const endOfPeriod = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate() + daysInPeriod,
      23,
      59,
      59
    );

    const timeLeft = endOfPeriod - currentTime;
    if (timeLeft <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return {
      days: days.toString().padStart(2, "0"),
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    };
  };

  const { days, hours, minutes, seconds } = getTimeLeft();

  const handleStartNow = () => {
    navigate("/plans");
  };

  return (
    <section
      className="nav-height d-flex justify-content-center ms-lg-5 container-fluid py-5 bg-white mb-0 mt-5 align-items-center mx-auto"
      style={{
        fontFamily: "Lato, sans-serif",
        maxWidth: "1800px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div className="d-flex justify-content-center">
        <div className="row align-items-center">
          {/* Left Side */}
          <motion.div
            className="col-lg-6 col-md-12 text-center text-md-start text-lg-start"
            variants={leftRightVariants}
          >
            <h2 className="display-5 fw-bold mb-3" style={{ color: "#26017b" }}>
              Everything to Bring Your Business to Life
            </h2>
            <p className="lead fw-bold">
              <span style={{ color: "#00a5e0" }}>Get Upto</span>{" "}
              <span style={{ color: "#26017b" }}>40% off</span>{" "}
              <span style={{ color: "#00a5e0" }}>+ 1 Month </span>
              <span style={{ color: "#26017b" }}>Free Plan*</span>
            </p>
            <ul
              className="list-unstyled fw-bold custom-list"
              style={{
                color: "#26017b",
              }}
            >
              <li className="mb-2">
                Ai Integration{" "}
                <img
                  src={ai}
                  alt="ai"
                  className="ms-2"
                  style={{ width: "20px", height: "20px" }}
                />
              </li>
              <li className="mb-2">24/7 Customer Support</li>
              <li className="mb-2">Digital Marketing and SEO</li>
            </ul>
            <p className="h2 fw-bold mb-1" style={{ color: "#00a5e0" }}>
              ₹ 9999/mo
            </p>
            <p className="mb-2">
              <span className="fw-bold" style={{ color: "#26017b" }}>
                +1 Month Free*
              </span>
            </p>

            <div className=" d-flex flex-column flex-md-row gap-3 mb-4 fw-bold">
              <button
                className="start-now  px-4 py-2 fw-bold"
                onClick={handleStartNow}
              >
                Start Now
              </button>
              {isLimitedTime && (
                <button className="timer  px-4 py-2">
                  {days} : {hours} : {minutes} : {seconds}
                </button>
              )}
            </div>

            {isLimitedTime && (
              <div className="d-flex align-items-center text-muted fw-bold">
                <img
                  src={timer}
                  alt="timer"
                  style={{ width: "20px", height: "20px" }}
                  className="me-1"
                />
                <span style={{ color: "#26017b" }}>Limited time deal*</span>
              </div>
            )}
          </motion.div>

          {/* Right Side */}
          <div className="col-lg-6 col-md-12 mt-5 mt-lg-0 d-flex justify-content-end align-items-end">
            <motion.img
              src={heroImage}
              alt="Digital marketing, Website development, App development, SEO services, Branding solutions, Digital agency "
              className="img-fluid mt-3"
              variants={leftRightVariants}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
