import React from "react";
import FuzzAds from "../assets/FuzzAds-Logo.png";
import facebook from "../assets/icons/facebook.png";
import instagram from "../assets/icons/instagram.png";
import twitter from "../assets/icons/twitter.png";
import youtube from "../assets/icons/youtube.png";
import linkedin from "../assets/icons/linkedin.png";
import centerImage from "../assets/Self-confidence-bro.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  

  return (
    <>
      <footer
        className=" py-2"
        style={{
          backgroundColor: "rgba(0, 165, 224, 0.13)",
          position: "relative",
        }}
      >
        <div className="container-fluid px-4">
          <div className="row align-items-start">
            {/* Left Column */}
            <div className="col-md-4 text-center d-flex flex-column align-items-center ps-md-0">
              {/* Logo Section */}
              <div className="mb-0" style={{ position: "relative" }}>
                <img
                  src={FuzzAds}
                  alt="FuzzAds"
                  style={{
                    width: "300px",
                    height: "auto",
                  }}
                />
              </div>
              {/* CEO */}
              <p className="mt-2 fs-5 fw-bold">
                <span style={{ color: "#26017b" }}>
                  Pratham Choudhary, CEO Fuzz
                  <span style={{ color: "#00a5e0" }}>Ads</span>
                </span>
              </p>
            </div>

            {/* Center Column (Hidden on Small Screens) */}
            <div className="col-md-4 d-none d-md-block text-center">
              <img
                src={centerImage}
                alt=" Full-stack development, Conversion rate optimization, Data-driven marketing, UI/UX design services, Cloud application development, Performance analytics."
                className="img-fluid"
                style={{ maxHeight: "350px" }}
              />
              <p
                className="mb-0 fw-bold"
                style={{
                  WebkitBackgroundClip: "text",
                  fontSize: "15px",
                  color: "#26017b",
                }}
              >
                © {currentYear} Fuzz
                <span style={{ color: "#00a5e0" }}>Ads</span>. All rights
                reserved.
              </p>
            </div>

            {/* Right Column */}
            <div className="col-md-4 d-flex justify-content-center mt-5">
              <div>
                <div className="row w-100">
                  {/* First Column in Right Section */}
                  <div className="col-6 ps-md-4 text-center">
                    <ul className="list-unstyled">
                      <li className="mb-4">
                        <a
                          href="/services"
                          className=" fw-bold fs-4"
                          style={{
                            textDecoration: "none",
                            color: "#26017b",
                            transition: "transform 0.2s ease",
                            display: "inline-block",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.color = "#00a5e0";
                            e.currentTarget.style.transform = "scale(1.03)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.color = "#26017b";
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        >
                          Services
                        </a>
                      </li>
                      <li className="mb-4">
                        <a
                          href="/contact"
                          className=" fw-bold fs-4"
                          style={{
                            textDecoration: "none",
                            color: "#26017b",
                            transition: "transform 0.2s ease",
                            display: "inline-block",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.color = "#00a5e0";
                            e.currentTarget.style.transform = "scale(1.03)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.color = "#26017b";
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        >
                          Contact <br />
                          Us
                        </a>
                      </li>
                      <li className="mb-4">
                        <a
                          href="/privacy-policy"
                          className=" fw-bold fs-4"
                          style={{
                            textDecoration: "none",
                            color: "#26017b",
                            transition: "transform 0.2s ease",
                            display: "inline-block",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.color = "#00a5e0";
                            e.currentTarget.style.transform = "scale(1.03)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.color = "#26017b";
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        >
                          Privacy
                          <br /> Policy
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* Second Column in Right Section */}
                  <div className="col-6  text-center">
                    <ul className="list-unstyled">
                      <li className="mb-4 mt-1">
                        <a
                          href="/about"
                          className=" fw-bold fs-4"
                          style={{
                            textDecoration: "none",
                            color: "#26017b",
                            transition: "transform 0.2s ease",
                            display: "inline-block",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.color = "#00a5e0";
                            e.currentTarget.style.transform = "scale(1.03)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.color = "#26017b";
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        >
                          About
                        </a>
                      </li>
                      <li className="mb-4">
                        <a
                          href="/terms-and-conditions"
                          className=" fw-bold fs-4"
                          style={{
                            textDecoration: "none",
                            color: "#26017b",
                            transition: "transform 0.2s ease",
                            display: "inline-block",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.color = "#00a5e0";
                            e.currentTarget.style.transform = "scale(1.03)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.color = "#26017b";
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        >
                          Terms & Conditions
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          href="/return-and-refund-policy"
                          className=" fw-bold fs-4"
                          style={{
                            textDecoration: "none",
                            color: "#26017b",
                            transition: "transform 0.2s ease",
                            display: "inline-block",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.color = "#00a5e0";
                            e.currentTarget.style.transform = "scale(1.03)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.color = "#26017b";
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        >
                          Return & Refund
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text-center w-100 mt-0">
                  <a
                    href="https://www.facebook.com/FuzzAds.co"
                    className="mx-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={facebook}
                      alt="facebook"
                      style={{ width: "40px", transition: "transform 0.2s" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/fuzzads.co/"
                    className="mx-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={instagram}
                      alt="instagram"
                      style={{ width: "40px", transition: "transform 0.2s" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/fuzzads/"
                    className="mx-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={linkedin}
                      alt="linkedin"
                      style={{ width: "40px", transition: "transform 0.2s" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </a>
                  <a
                    href="https://x.com/Fuzz_Ads"
                    className="mx-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={twitter}
                      alt="twitter"
                      style={{ width: "40px", transition: "transform 0.2s" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@Fuzz-Ads"
                    className="mx-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={youtube}
                      alt="youtube"
                      style={{ width: "40px", transition: "transform 0.2s" }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  </a>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
